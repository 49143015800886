import React from 'react';
import Main from '../containers/Layout';
import securityContent from '../../content/pages/security.yml';
import SecurityContainer from '@/containers/Security';

export interface SecurityPageContent {
  header: {
    title1: string;
    title2: string;
    subtitle1: string;
    subtitle2: string;
  };
  body: string;
}
const SecurityPage: React.FunctionComponent = () => {
  const content = securityContent as unknown as SecurityPageContent;
  return (
    <Main>
      <SecurityContainer content={content} />
    </Main>
  );
};

export default SecurityPage;
